// useDocumentTitle.js
import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = true) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (document.title.length === 0) {
      document.title = "iPlay ApS";
    } else {
      document.title = `${title}`;
    }
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}

export default useDocumentTitle;
