// ELGIGANTEN
import { ReactComponent as IconELGIGANTENLogo } from "../assets/elgiganten/ELGIGANTEN_LOGO.svg";
import { ReactComponent as IconELGIGANTENJewl } from "../assets/elgiganten/shape.svg";
import { ReactComponent as IconELGIGANTENPlayer2 } from "../assets/elgiganten/player_2_kill_box.svg";
import { ReactComponent as IconELGIGANTENPlayer1 } from "../assets/elgiganten/player_1_kill_box.svg";
import { ReactComponent as IconELGIGANTENCornerLeft } from "../assets/elgiganten/Corner_left.svg";
import { ReactComponent as IconELGIGANTENCornerRight } from "../assets/elgiganten/Corner_right.svg";

// TCL
import { ReactComponent as IconTCLLogo } from "../assets/tcl/TCL_LOGO.svg";
import { ReactComponent as IconTCLCornerLeft } from "../assets/tcl/Corner_left.svg";
import { ReactComponent as IconTCLCornerRight } from "../assets/tcl/Corner_right.svg";
import { ReactComponent as IconTCLJewl } from "../assets/tcl/shape.svg";
import { ReactComponent as IconPlayer1 } from "../assets/tcl/pink_cube-left.svg";
import { ReactComponent as IconPlayer2 } from "../assets/tcl/blue_cube-right.svg";

// PCSPECIALIST
import { ReactComponent as IconPCSPECIALISTLogo } from "../assets/pcspecialist/pcspecialist_logo.svg";
import { ReactComponent as IconPCSPECIALISTCornerLeft } from "../assets/pcspecialist/Corner_left.svg";
import { ReactComponent as IconPCSPECIALISTCornerRight } from "../assets/pcspecialist/Corner_right.svg";
import { ReactComponent as IconPCSPECIALISTJewl } from "../assets/pcspecialist/shape.svg";
import { ReactComponent as IconPCSPECIALISTPlayer1 } from "../assets/pcspecialist/The whole LEFT piece PC spec.svg";
import { ReactComponent as IconPCSPECIALISTPlayer2 } from "../assets/pcspecialist/The whole piece PC spec.svg";

export const theme = {
  TCL: {
    themeStyleValue: "tcl",
    logo: <IconTCLLogo width={342} height={80} />,
    cornerLeft: <IconTCLCornerLeft width={52} height={52} />,
    cornerRight: <IconTCLCornerRight width={98} height={36} />,
    scoreJewl: <IconTCLJewl width={250} height={194} />,
    player1Name: "Hero",
    player2Name: "Villain",
    centerPlayer1: <IconPlayer1 width={250} />,
    centerPlayer2: <IconPlayer2 width={250} />,
  },

  ELGIGANTEN: {
    themeStyleValue: "elgiganten",
    logo: <IconELGIGANTENLogo width={342} height={80} />,
    cornerLeft: <IconELGIGANTENCornerLeft width={52} height={52} />,
    cornerRight: <IconELGIGANTENCornerRight width={98} height={36} />,
    scoreJewl: <IconELGIGANTENJewl width={250} height={194} />,
    player1Name: "Player 1",
    player2Name: "Player 2",
    centerPlayer1: <IconELGIGANTENPlayer1 width={250} />,
    centerPlayer2: <IconELGIGANTENPlayer2 width={250} />,
  },

  PCSPECIALIST: {
    themeStyleValue: "pcspecialist",
    logo: <IconPCSPECIALISTLogo width={342} height={80} />,
    cornerLeft: <IconPCSPECIALISTCornerLeft width={52} height={52} />,
    cornerRight: <IconPCSPECIALISTCornerRight width={98} height={36} />,
    scoreJewl: <IconPCSPECIALISTJewl width={250} height={194} />,
    player1Name: "Player 1",
    player2Name: "Player 2",
    centerPlayer1: <IconPCSPECIALISTPlayer1 />,
    centerPlayer2: <IconPCSPECIALISTPlayer2 />,
  },
};
