import React from "react";
import { GridItemProps } from "../Grid/GridProps.types";

const Col: React.FC<GridItemProps> = ({ children, start, end }) => {
  const startClasses = start ? `grid__start--${start}` : "";
  const endClasses = end ? `grid__end--${end}` : "";

  return (
    <div
      className={`grid__item ${startClasses && startClasses} ${
        endClasses && endClasses
      } `}
    >
      <div>{children}</div>
    </div>
  );
};

export default Col;
