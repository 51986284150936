import { TCL_TWITCH_ScoreBoard } from "./clients/TCL/Scoreboard";
import "./globalStyles/index.scss";

import { CS_ScoreBoard } from "./clients/CS/Scoreboard"; //Replacement for any cs scoreboards
import BrowserInfo from "./components/BrowserInfo/BrowserInfo";

function App() {
  const client = window.location.pathname;

  type ScoreBoardMap = {
    [key: string]: JSX.Element;
  };

  const scoreBoardToRender = {
    "/tcl": <TCL_TWITCH_ScoreBoard />,
    "/tcl-logs": <CS_ScoreBoard client={"TCL"} />,
    "/pcspecialist": <CS_ScoreBoard client={"PCSPECIALIST"} />, // NEED WORKS BEFORE USE!
    // "/pcspecialist": <PCSPECIALIST_ScoreBoard />, // original!
    "/elgiganten": <CS_ScoreBoard client={"ELGIGANTEN"} />,
    "/cs": <CS_ScoreBoard client={"ELGIGANTEN"} />,
  } as ScoreBoardMap;

  if (client.replace("/", " ").trim() === "") {
    return (
      <div className="app">
        <h1>No client found, /clientName missing</h1>
      </div>
    );
  }

  if (window.location.pathname.includes("test")) {
    return <BrowserInfo />;
  }

  return (
    <div className="app">
      {scoreBoardToRender[client] || "no scoreboard found with this name"}
    </div>
  );
}

export default App;
