import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./utils/scss/main.scss";
import firebase from "firebase/app";
import { auth } from "@/BE/firebaseConfig.ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (!auth.currentUser?.uid) {
  firebase
    .auth()
    .signInWithEmailAndPassword(
      "leaderboardauthuser@gmail.com",
      "leaderboardauthuser"
    )
    .then(() => {
      firebase.auth().currentUser?.reload();
    });
}
// Check if client exists
if (!window.location.pathname) {
  root.render(<>Client not found</>);
} else {
  root.render(<App />);
}
