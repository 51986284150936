import { Events } from "@/hooks/useUserDataHook";
import { cs2Weapons } from "@/consts/cs2Weapons";
import { FeedProps } from "./Feed.types";

import { getTime } from "@/helperFunctions/formatMsToTime";
import { useEffect, useState } from "react";

const Feed: React.FC<FeedProps> = ({ logs, themeData }) => {
  const [windowheight, setWindowheight] = useState<boolean>();

  useEffect(() => {
    function handleResize() {
      setWindowheight(window.innerHeight >= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!logs) {
    return <h1>No logs found</h1>;
  }

  const limit = windowheight ? 4 : 3;
  return (
    <div className="event">
      {logs?.map((data: Events, index) => {
        if (index > limit) {
          return;
        }

        const player = data.player === "player1" ? 1 : 0;
        const key = index;

        const isPlayer1 = player === 1;
        const alignment = isPlayer1 ? "align-left" : "align-right";

        return (
          <div key={key} className={`event__item ${alignment} log_${index}`}>
            <div className={`event__content ${alignment}`}>
              <div className="event__body">
                <div className="event__weapon">
                  {cs2Weapons[data.weaponName] || data.weaponName || "unknown"}{" "}
                </div>
              </div>
            </div>
            <div className={`event__shape ${alignment}`}>
              {isPlayer1 ? themeData.centerPlayer1 : themeData.centerPlayer2}
            </div>
            <div className={`event__type ${alignment}`}>
              {data.wasHeadshot ? "Headshot" : "Kill"}
            </div>

            <div className={`event__timestamp ${alignment}`}>
              <time>{getTime(data.timestamp)}</time>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Feed;
