import { FC } from "react";
import "./styles/index.scss";

import TclLogo from "./assets/TCL_LOGO.svg";
import { ReactComponent as ScoreJewl } from "./assets/Score_shape.svg";
import { ReactComponent as IframeFrame } from "./assets/frame.svg";
import { ReactComponent as MetaCornerLeft } from "./assets/Corner_left.svg";
import { ReactComponent as MetaCornerRight } from "./assets/Corner_right.svg";
import { ReactComponent as ScoreStar } from "./assets/score_star.svg";
import { ReactComponent as ScoreStartGray } from "./assets/score_star_gray.svg";
import { useLeaderboardData } from "@/hooks/useUserDataHook";

export const TCL_TWITCH_ScoreBoard: FC<{}> = ({}) => {
  const { usersData, logs, reset } = useLeaderboardData("TCL", "TCL");

  const player1Score = usersData?.player1?.kills || 0;
  const player2Score = usersData?.player2?.kills || 0;

  const siteName = window.location.hostname;

  /*Champion -------*/
  const championTwitchUserName = "iplaydk01";
  const championTwitchUrl = `https://player.twitch.tv/?&channel=${championTwitchUserName}&muted=true&parent=${siteName}`;

  /*Challenger -----*/
  const challengerTwitchUserName = "iplaydk02";
  const challengerTwitchUrl = `https://player.twitch.tv/?channel=${challengerTwitchUserName}&muted=true&parent=${siteName}`;

  return (
    <div className="tcl">
      <div className="scoreboard">
        <div className="scoreboard__heading">
          <img src={TclLogo} alt="" />
        </div>

        <div className="scoreboard__meta">
          <figure className="shapeTopLeft">
            <MetaCornerLeft />
          </figure>
          <figure className="shapeBottomRight">
            <MetaCornerRight />
          </figure>
          <div className="meta">
            <section>
              <h2>Player 1</h2>
            </section>
            <section />
            <section>
              <h2>Player 2</h2>
            </section>
          </div>
          <div className="scoreboard__shape">
            <ScoreJewl />
            <div className="meta__score">
              <p>{player1Score}</p> <p>:</p> <p>{player2Score}</p>{" "}
            </div>
          </div>
        </div>

        <div className="scoreboard__screens">
          <div className="screens">
            <section className="screens__left">
              <div>
                <IframeFrame />
                <iframe
                  width="100%"
                  height="100%"
                  src={championTwitchUrl}
                  allow="autoplay;"
                />
              </div>

              <ul className="screens__counter">
                {" "}
                {player1Score
                  ? Array.from(Array(player1Score), (_) => {
                      return <ScoreStar />;
                    })
                  : ""}
                {Array.from(Array(7 - player1Score), (_) => {
                  return <ScoreStartGray />;
                })}
              </ul>
            </section>

            <section className="screens__right">
              <div>
                <IframeFrame />
                <iframe
                  src={challengerTwitchUrl}
                  width="100%"
                  height="100%"
                ></iframe>
              </div>

              <ul className="screens__counter">
                {Array.from(Array(7 - player2Score), (_) => {
                  return <ScoreStartGray />;
                })}

                {player2Score
                  ? Array.from(Array(player2Score), (_) => {
                      return <ScoreStar />;
                    })
                  : ""}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
