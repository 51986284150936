import React from "react";
import { GridProps } from "./GridProps.types";

const Grid: React.FC<GridProps> = ({ columns, children }) => {
  const variantClasses = `grid grid__${columns}col`;

  return <div className={`${columns && variantClasses} `}>{children}</div>;
};

export default Grid;
