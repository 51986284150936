import { useEffect, useState } from "react";
import { database_1 } from "../BE/firebaseConfig";

export type Props = {
  matchState: "match_started" | "match_ended";
  player1: {
    kills: number;
  };
  player2: {
    kills: number;
  };
  events: Events[];
};

export type Events = {
  kills: number;
  deaths: number;
  weaponName: string;
  wasHeadshot: boolean;
  player: "player1" | "player2";
  timestamp: EpochTimeStamp;
};
export const useLeaderboardData = (client, scoreboardVersion) => {
  const [usersData, setUsersData] = useState<Props>(null);
  const [logs, setLogs] = useState(null);

  const realtimeDB = database_1
    .ref("cs2")
    .child(client)
    .child("match_01")
    .orderByKey();

  const fetchAllLeaderboard = async () => {
    realtimeDB.on("value", async (snapshot) => {
      if (snapshot.val()) {
        if (scoreboardVersion === "TCL") {
          setTimeout(() => {
            setUsersData(snapshot.val());
          }, 3500);
        } else {
          setUsersData(snapshot.val());
        }

        if (snapshot.val()?.events) {
          const dataArray = Object.entries(snapshot.val()?.events).map(
            ([key, value]: any) => ({
              key,
              ...value,
            })
          );

          dataArray.sort((a, b) => b.timestamp - a.timestamp);
          const clearedArr = dataArray;
          console.log(clearedArr);

          setLogs(clearedArr);
        }
      }
    });
  };

  const reset = () => {
    setUsersData(null);
    setLogs(null);
  };

  useEffect(() => {
    fetchAllLeaderboard();
  }, []);

  // // Cleanup function to unsubscribe from the database listener
  // return () => {
  //     realtimeDB.off();
  // };

  return { usersData, logs, reset };
};
